<template>
<div class="mx-auto px-5 py-5">
  <Loading :isLoading= "this.isLoading" />
  <div>
    <p class="title sm:text-left; md:text-center">Cargar comprobante de pago</p>
    <br>
    <p class="subtitle">
      <!--Si usted ha realizado el pago de su inscripción por medio de transferencia bancaria, o bien, 
      en la caja de banco, cargue el documento en la siguiente sección:-->
      Si usted ha realizado el pago de su inscripción en banco, cargue el documento en la siguiente sección:
    </p>
    <div style="display:flex;justify-content:center;">
      <div class="grid grid-cols-3 gap-4 container-grid">
        <!--<div class="col-span-2">
          <input class="inputsForm italic" id="input_document_name" style="width:100%;height:40px;" type="text" v-model="documentName" 
            placeholder="Nombre del comprobante">
        </div>-->
        <div>
          <!--<input v-if="!voucherUploaded" type="button" value="Cargar Archivo" id="button_file_upload" style="height:40px;" class="upload_button" v-on:click="getFile('input_file_upload')">-->
          <!--<input v-if="voucherUploaded" type="button" value="Eliminar Archivo" id="button_file_delete" style="height:40px;" class="deleteDocumentButton" v-on:click="deleteDocument()"><br/>-->
          <div style='height: 0px;width:0px; overflow:hidden;'><input id="input_file_upload" type="file" accept=".pdf,.png,.PNG,.jpeg" @change="loadDocument($event)"/></div>
        </div>
      </div>
    </div>
    <br>
    <!--<div style="display:flex;justify-content:center;margin-top:10px;">
      <div class="grid grid-cols-3 gap-4 container-grid">
        <div class="col-span-3">
          <input type="date">
        </div>
      </div>
    </div>-->
    <div style="display:flex;justify-content:center;margin-top:10px;">
      <div style="ori">
        <p class="subtitle" style="margin-top:15px;margin-bottom:0px;">
          Fecha de depósito:
        </p>
        <date-picker
          simple
          color="#810042"
          placeholder="Fecha de depósito"
          locale="es"
          input-format="DD/MM/YYYY"
          @change="selectDate"
          :localeConfig="{
            es: {
              displayFormat: 'DD/MM/YYYY',
                lang: {
                  year: 'Año',
                  month: 'Mes',
                  day: 'Día',
                  label: '',
                  submit: 'Seleccionar',
                  cancel: 'Cancelar',
                  now: 'Hoy',
                  nextMonth: 'Siguiente',
                  prevMonth: 'Anterior',
                },
              }
          }" />
      </div>
    </div>
    <br>
    <div style="display:flex;justify-content:center;margin-top:10px;">
      <div class="grid grid-cols-3 gap-4 container-grid">
        <div class="col-span-3">
          <!--<input class="button_popups" type="button" v-on:click="upload()" value="Subir comprobante de pago" />-->
          <input v-if="!voucherUploaded" type="button" value="Cargar Archivo" id="button_file_upload" style="height:40px;" class="button_popups" v-on:click="getFile('input_file_upload')">
        </div>
      </div>
    </div>
    <div>
      <p class="subtitle" style="margin-top:15px;">
        *Si no ha realizado su pago puede hacerlo con los siguientes datos
      </p>

      <div>
      <p class="subtitle" style="margin-top:15px;margin-bottom:0px;">
        Banco:
      </p>
      <p class="subtitleValue flex md:justify-center justify-left" style="margin-top:0px;">
        ACTINVER SA
      </p>
      <p class="subtitle" style="margin-top:15px;margin-bottom:0px;">
        CLABE:
      </p>
      <p class="subtitleValue flex md:justify-center justify-left" style="margin-top:0px;">
        133180000127863072
        <a v-on:click="copy('133180000127863072','CLABE')" style="margin-top:4px;margin-left:3px"><img src="@/assets/copy.png" class="img-responsive" style="width:20px; height:20px"></a>
      </p>
      <p class="subtitle" style="margin-top:15px;margin-bottom:0px;">
        Nombre:
      </p>
      <p class="subtitleValue" style="margin-top:0px;display:flex;justify-content:center;">
        FONCABSA S.C DE A.P. DE R.L DE C.V.
      </p>
      <p class="subtitle" style="margin-top:15px;margin-bottom:0px;">
        Cantidad a depositar:
      </p>
      <p class="subtitleValue flex md:justify-center justify-left" style="margin-top:0px;">
        ${{priceEnrollPaymentFee}}
      </p>
      <br>
      <a style="display:flex;justify-content:center;" href="javascript:window.print()"><img src="@/assets/print.png" class="img-responsive" style="width:40px; height:40px"></a>
    </div>

      <!--<p class="subtitle" style="margin-top:15px;margin-bottom:0px;">
        Referencia:
      </p>
      <p class="subtitleValue" style="margin-top:0px;">
        #133180000127863072
      </p>
      <p class="subtitle" style="margin-top:15px;margin-bottom:0px;">
        Banco:
      </p>
      <p class="subtitleValue" style="margin-top:0px;">
        ACTINVER SA
      </p>
      <p class="subtitle" style="margin-top:15px;margin-bottom:0px;">
        Cantidad a depositar:
      </p>
      <p class="subtitleValue" style="margin-top:0px;">
        $400.00
      </p>-->
    </div>
  </div>
</div>
</template>



<script>
import('@/assets/css/stylesheet.css')
import Loading from "@/components/Loading/VueLoading.vue";
import Document from '@/classes/Document'
import {getTokenDecoden} from "@/helpers/tokenauth";
import { getAdmissionRequest } from '@/api/user';
import Swal from "sweetalert2";
import Parameter from '@/classes/Parameter'
import moment from "moment";

export default {
  data() {
    return {
      personId: null,
      admissionStatus: "",
      admissionRequestId: null,
      slideIndex: 1,
      isLoading: false,
      voucherUploaded: false,
      document: {
        file: "",
        extension: ""
      },
      documentName: "",
      ccapi: process.env.VUE_APP_CCAPI,
      urlWeb: process.env.VUE_APP_URLWEB,
      confirmPayment: false,
      documentObjectClass: null,
      priceEnrollPaymentFee: "0.00",
      paymentDate:"",
      employerName: "ACTINVER"
    }
  },
  name: 'PaginaPrincipal',
  props: {
    msg: String
  },
  components: {
    Loading
  },
  async mounted() {
    this.documentObjectClass = new Document();
    await this.loadInfo();
    await this.getAdmissionRequest();
    this.parameterObjectClass = new Parameter();
    this.parameterObjectClass.get(sessionStorage.getItem("login"),"pago parte social").then((response)=>{
      this.priceEnrollPaymentFee = Number(response.data.response.parameter).toFixed(2);
    })
  },
  beforeMount() {
  },
  methods:{
    async loadInfo() {
      var auth = getTokenDecoden();
      this.personId = auth.obj.person["id"]
    },
    async getAdmissionRequest() {
      let token = sessionStorage.getItem("login");
      let person_id = this.personId;
      this.isLoading = true;
      await getAdmissionRequest(
          token,
          person_id
      )
      .then((response) => {
        if (response.data.response.admission_request != null) {
          this.admissionRequestId = response.data.response.admission_request.id
          console.log("admission_request_id: "+this.admissionRequestId);
        }
        this.isLoading = false;
      })
      .catch((error) => {
        this.isLoading = false;
        Swal.fire({
            title: "Aviso",
            text: "Se presentó un error al consultar las solicitudes",
            icon: "error",
            confirmButtonColor: '#FEB72B',
        });
      });
    },
    Logout(){
      sessionStorage.clear();
      //localStorage.clear();
      // window.location.href = "/"
      this.$router.push({path:"/"});
    },
    getFile(selector) {
      if(this.paymentDate == ""){
        var swal = require('sweetalert2')
        Swal.fire({
          title: "Aviso",
          text:
            "Favor de seleccionar una fecha de depósito.",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        return;
      }
      this.documentObjectClass.getFileByIdSelector(selector);
    },
    loadDocument(event) {
      let file = event.target.files;
      let filename = file[0].name;
      let extension = file[0].type.split('/')[1];

      this.documentName = `${filename}.${extension}`;

      this.documentObjectClass.serialize(file[0]).then(base64Response => {
        this.setDocumentList(base64Response, extension);
        //this.showLoadedDocumentSuccessMessage(filename);
        this.upload()
        //this.voucherUploaded = true;
      }).catch(error => {
        console.error(error);
      });
    },
    setDocumentList(fileBase64, extension) {
      this.document = {
        file: fileBase64,
        extension: extension,
      };
      console.log(this.document);
    },
    showLoadedDocumentSuccessMessage(documentName) {
      Swal.fire({
        text: "Solicitud de prestamo guardada correctamente",
        html: `<p style="font-size:14px;font-family:Roboto;font-weight:400;">El documento: <span style="font-weight:500;">${documentName}</span> se ha adjuntado correctamente.</p>`,
        confirmButtonText: "Continuar",
        allowOutsideClick: false,
        icon: "success",
        confirmButtonColor: '#FEB72B',
      });
    },
    deleteDocument(){
      Swal.fire({
        title: "Aviso",
        customClass: {
          container: 'my-swal'
        },
        text: "¿Desea eliminar el documento?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "SI",
        cancelButtonText: "NO",
        confirmButtonColor: '#FEB72B',
        cancelButtonColor: '#FEB72B',
      })
      .then((result) => {
        if (result.value) {
          this.documentName = "";
          this.document = {
            file: "",
            extension: ""
          }
          this.voucherUploaded = false;
        }
      });
      this.setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_delete', 'button_cancel_delete');
    },
    setIdAttributeToAccepAndCancelButtonSwalFire(idButtonConfirm, idButtonCancel) {
      $('.swal2-confirm').attr('id', idButtonConfirm);
      $('.swal2-cancel').attr('id', idButtonCancel);
    },
    upload() {
      Swal.fire({
          title: "Aviso",
          html: `<p style="font-size:14px;font-family:Roboto;font-weight:400;">¿Desea subir el comprobante?</p>`,
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "SI",
          cancelButtonText: "NO",
          confirmButtonColor: '#FEB72B',
          cancelButtonColor: '#FEB72B',
      })
      .then((result) => {
        if (result.value) {
          if(this.document.file != "") {
            this.isLoading = true;
            this.documentObjectClass.uploadVoucher(sessionStorage.getItem("login"), this.document.file, this.document.extension, "transferencia", this.admissionRequestId, this.paymentDate, this.employerName).then(response => {
              console.log(response);
              if(response.data.response.message == "Se subio correctamente el recibo de pago"){
                this.isLoading = false;
                this.$router.push({path:"/cuenta/pago-parte-social/comprobante-adjuntado-exitosamente"});
              }else{
                this.isLoading = false;
                Swal.fire({
                  html: `<p style="font-size:14px;font-family:Roboto;font-weight:400;">${response.data.response.message}</p>`,
                  confirmButtonText: "Continuar",
                  allowOutsideClick: false,
                  icon: "info",
                  confirmButtonColor: '#FEB72B',
                });
              }
              
            }).catch(error => {
              this.isLoading = false;
              Swal.fire({
                html: `<p style="font-size:14px;font-family:Roboto;font-weight:400;">${error}</p>`,
                confirmButtonText: "Continuar",
                allowOutsideClick: false,
                icon: "error",
                confirmButtonColor: '#FEB72B',
              });
            });
          } else {
            Swal.fire({
              html: `<p style="font-size:14px;font-family:Roboto;font-weight:400;">Favor de seleccionar el comprobante de pago.</p>`,
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
          }
        }
      });
    },
    copy(text,name){
      var aux = document.createElement("input");
      aux.setAttribute("value", text);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);

      var swal = require('sweetalert2')
      var namee = name + " copiada correctamente."
      swal.fire({
          title: "Aviso",
          text: namee,
          icon: "success",
          confirmButtonColor: '#FEB72B',
      })
    },
    selectDate(event){
      var swal = require('sweetalert2')
      if((moment(event._d).format("YYYY-MM-DD")) > (moment().format("YYYY-MM-DD"))){
        Swal.fire({
          title: "Aviso",
          text:
            "La fecha de depósito tiene que ser menor o igual a la actual.",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        this.paymentDate = ""
        return;
      }
      else{
        this.paymentDate = moment(event._d).format("DD/MM/YYYY")
      }
    }
  }
}
</script>

<style scoped>
.button_popups {
  border-radius: 5px;
  background-color: #FEB72B;
  color: #fff;
  font-family: Roboto;
  font-weight:400;
  width: 100%;
  height: 40px;
  padding:10px;
  font-size:16px;
}

.container-grid {
  width: 100%;
}

.upload_button {
  border-radius: 5px;
  background-color: #DEA610;
  color: #fff;
  font-weight:bold;
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size:14px;
}
.deleteDocumentButton {
  width: 100%;
  background-color: #ff0000;
  font-family: Roboto;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  border-radius: 5px;
}
.inputsForm {
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}

.italic {
  font-style: italic;
}

.description {
  font-size:20px;
  font-family:Roboto;
  color: #000;
  font-weight: 300;
  margin-top:0px;
  text-align:left;
}
.subtitle {
  font-size:20px;
  font-family:Roboto;
  color: #000;
  font-weight: 300;
  text-align:left;
}

.subtitleValue {
  font-size:20px;
  font-family:Roboto;
  color: #000;
  font-weight: 500;
  text-align: left;
}
@media only screen and (min-width: 1024px) {
  .container-grid {
    width: 40%;
  }

  .subtitle {
    text-align: center;
  }

  .subtitleValue {
    text-align: center;
  }
}

</style>